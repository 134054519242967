@use "../base/_responsive.scss" as responsive;



.docs__list{
    margin: 24px 0px;
}

.docs__document{
    border-top: 2px solid var(--main-font-color);
    &:last-child{
        border-bottom: 2px solid var(--main-font-color);
    }

    box-sizing: border-box;
    padding: 18px 0px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p{
        max-width: 50%;
    }


}

@media (min-width: responsive.$tablet){



.docs__document{
    max-width: responsive.$small;
    margin: 0 auto;
}

}
    


@use "../base/_responsive.scss" as responsive;

.home-articles{
    display: grid; 
    grid-template-columns: 1fr;  
    grid-gap: 1.5rem;
}

.home-article{
    padding: 2rem 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    min-height: 200px;
}

.home-article-title,
.home-article-intro,
.home-article-lien{
    font-family: "Inter", Sans-serif;
    text-align: center;
    color: var(--white-font-color);
    max-width: 400px;
}

.home-article-textContainer{
    padding-bottom: 3.25rem;
}

.home-article-title{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.home-article-intro{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    height: 1.4 * 1.2rem * 6;
    overflow: hidden;
}

.home-article-lien{
    display: block;
    color: var(--main-color);
    background-color: var(--white-color);
    padding: 0.75rem 1.5rem;
    border-radius: 1rem;
    transition: .3s;
    &:hover{
        //    background-color: var(--grey-color);
        background-color: var(--main-color);
        color: var(--white-color);
    }
}

.home-article-tousLesArticles{
    color: var(--main-color);
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 2rem;

    font-size: 1rem;

    text-transform: uppercase;
    text-decoration: underline;
}

@media (min-width: responsive.$small){

    .home-articles{
        grid-template-columns: repeat(2, 1fr);  
    }
}

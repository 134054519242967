@use "../base/_responsive.scss" as responsive;

.home-expert{
    display: grid; 
    grid-template-columns: 1fr;
    grid-gap: 1.25rem;

    padding-top: 2rem;
}

.home-expert-imageContainer{
    width: 100%;
    height: 300px;
}

.home-expert-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media (min-width: responsive.$small){

    .home-expert{
        grid-template-columns: repeat(2, 1fr);
    }

    .home-expert-imageContainer{
        min-height: 400px;
    }
}

@use "../base/_responsive.scss" as responsive;


$contactMaxWidth: 724px;

.contactForm{
    margin-top: 24px;
}

.form__group{
    margin-top: 36px;
    margin-bottom: 12px;

    &.alignEnd{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}

.form__label{
    font-size: 1.2rem;
}

.mandatory{
    font-size: 0.8em;
    color: #ee202c;
}

.form__input{
    font-size: 1.2rem;
    padding: 6px 9px;
    border: 1px solid var(--grey-color);
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    color: var(--main-font-color);
    font-weight: 400;

    &--large{
        min-height: 120px;
    }

    &:focus{
        font-weight: 500;
    }
    &::placeholder{
        color: var(--grey-font-color);
    }
}

.form__button{
    font-size: 1.2rem;
    background-color: var(--main-color);
    color: var(--white-color);

    display: block;
    padding: 12px 16px;
    border-radius: 12px;
    border: 2px solid var(--main-color);
    transition: .3s;

    cursor: pointer;

    &::after{
        content: " →";
        margin-left: 0px;
        transition: .3s;
    }
    
    &:hover{
        background-color: var(--white-color);
        color: var(--main-color);
        &::after{
            margin-left: 3px;
        }
    }
}


.flash{
    background-color: var(--grey-color);
    color: var(--black-font-color);
    width: 100%;
    max-width: $contactMaxWidth;
    padding: 12px;
    line-height: 1.4;
    border-radius: 12px;
    font-weight: 500;
    box-sizing: border-box;
    margin: 0 auto;
    &.error{
        background-color: #ffdfe1;
        border: 3px solid #ff888f;
    }
    &.succes{
        background-color: #e9ffe3;
        border: 3px solid #adf6ad;
    }
}

.form__text{
    margin-bottom: 56px;
}

@media (min-width: responsive.$tablet){

    .main--contact{
        display: flex;
        flex-direction: row;
    }

    .contactForm{
        margin: 0 auto;
        width: $contactMaxWidth;
        box-sizing: border-box;
    }

    .contactForm-form{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 3rem;
        grid-template-rows: 1fr;
    }
    
    .grid-span-2{
        grid-column: span 2;
    }
}

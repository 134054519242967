@use "../base/_responsive.scss" as responsive;

.article{
    width: 100%;

    margin: 24px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
}

.article__back{
    position: absolute;
    left: 0px;
    top:0px;

    &--bottom{
        top: auto;
        bottom: 0px;
    }
}

.article__section{
    max-width: 720px;
    padding-top: 24px;
    padding-bottom: 72px;
}

@media (min-width: responsive.$tablet){


    
}

@use "../base/_responsive.scss" as responsive;

$largeurCalculatrice : 720px;

.tarifs__title{
    font-size: calc(1px + 1.5rem);
    margin-top: 48px;
    margin-bottom: 24px;
    text-align: center;
}

.calculatrice{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
}

.calculatrice__text{
    width: 100%;
    margin-bottom: 24px;
}

.calculatrice__menu{
    border: 2px solid var(--grey-color);
    box-sizing: content-box;
    padding: 16px 24px;
    margin: 0 auto;
    border-radius: 24px 24px;

    overflow: hidden;

    width: 100%;

    &--transition{
        .small-title, .calculatrice__Allbuttons{
            animation: transitionOutIn 1s cubic-bezier(0.25, 1.1, 0.9, 1) 0s 1 normal none;
        }
        
    }

    &--transitionBack{
        .small-title, .calculatrice__Allbuttons{
            animation: transitionOutIn 1s linear 0s 1 reverse none;
        }
    }
    &[data-finished]{
        .calculatrice__estimation{
            display: none;
        }
        #question{
            display: block;
        }
    }
    &[data-finished="true"]{
        .calculatrice__estimation{
            display: block;
            color: var(--main-color);
            font-size: 32px;
            font-weight: 500;
            letter-spacing: 0.5px;
            .calculatrice__output{
                font-weight: 700;
            }
            text-align: center;
            width: 100%;
        }
        #question{
            display: none;
        }
    }
}


@keyframes transitionOutIn {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }
    

    35% {
        opacity: 0;
        transform: translate(-70px, 0);
    }


    65%{
        opacity: 0;
        transform: translate(70px, 0);
    }


    100% {
        opacity: 1;
        transform: translate(0, 0);
    }

  }

.calculatrice__Allbuttons{
    margin-top: 32px;

    display: grid; 

    grid-template-rows: 1fr;
    grid-template-columns:  repeat(2,1fr);
    
    gap: 12px 8px;

}

.calculatrice__bottom{
    background-color: var(--grey-color);
    width: 100%;
    height: 48px;
    border-radius: 0px 0px 24px 24px;

    color: var(--black-font-color);
    font-weight: 400;
    strong{
        font-weight: 600;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

 
}

.calculatrice__button{
    width: 100%;
 //   height: 48px;
    box-sizing: border-box;
    padding: 8px 12px;
    margin: 0;

    text-align: center;

    border-radius: 24px;
    background-color: var(--white-color);
    border: 2px solid var(--grey-color);

    font-weight: 400;
    cursor: pointer;

    transition: .3s;

    &:hover{
        font-weight: 500;
        background-color: var(--main-color);
        color: var(--white-font-color);
    }
    &--hidden{
        display: none;
    }
}

.calculatrice__back{
    background-color: var(--main-color);
    border: 2px solid var(--main-color);
    color: var(--white-color);
    margin-top: 12px;
    box-sizing: border-box;

    padding: 12px;
    border-radius: 50%;
    line-height: 1;
    font-weight: 800;

    cursor: pointer;
    transition: .3s;
    &:hover{
        background-color: var(--white-color);
        color: var(--main-color);
    }
}

@media (min-width: responsive.$tablet){

.calculatrice__text, .calculatrice__menu, .calculatrice__bottom{
    max-width: responsive.$small;
}

}
    


@use "../base/_responsive.scss" as responsive;

.header{
    background-color: var(--grey-color);
    border-bottom: 1px solid var(--grey-color);

    height: 48px;
    max-width: 100%;
  //  @media (min-width: responsive.$veryLarge){
    width: responsive.$veryLarge;
  //  }

    box-sizing: border-box;
    padding: 8px 16px;

    
    top: 0px;
    left: 0px;

    transition: height 0.3s;

    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}


// DIVS PRINCIPALES
.header__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
}

.header__bottom{
    overflow: hidden;

    height: calc(100% - 32px );
    height: 0px;
    transition: height 0.3s;
}




.header__logo{
    height: 32px;
}

.svgStroke{
    stroke: var(--main-color);
    fill: none;
}
.svgFill{
    fill: var(--main-color);
}

.header__links{
    line-height: 3;
}

.header__link{

    font-size: 14px;
    color: var(--grey-font-color);
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-weight: 400;

    opacity: 0;
    transform: translateX(24px);
    transition: .15s;


    &.active{
        color: var(--main-font-color);
        a::after{
            width: 100%;
        }
    }

    &.phonelink{

        max-width: 175px;

        a{

        display: flex;
        flex-direction: row;
        align-items: center;

        justify-content: center;
        color: var(--white-font-color);
        background-color: var(--main-color);
        border: 2px solid transparent;
        transition: .15s;

        border-radius: 18px;

            svg{
                width: 24px;
                margin-right: 8px;
            }

        }

        &:hover a{
            color: var(--main-color);
            background-color: transparent;
            border: 2px solid var(--main-color);
        }
    }

    a{
        color: inherit;
        position: relative;
        transition: color 0.3s;

        &::after{
            content: "";
            position: absolute;
            left: 0px;
            bottom: -1px;
            border-radius: 2px;
            height: 2px;
            width: 0%;
            background-color: var(--main-font-color);
        }
        &:hover{
            color: var(--main-font-color);
        }
    }
}




// gestion opened mobile

.header--opened{
    height: 100vh;

    .header__bottom{
        height: calc(100% - 32px );
    }

    .header__link{
        transform: translateX(0px);
        opacity: 1;
        transition: .2s;
        @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
                transition-delay: ( 0.125s * $i);
            }
      }

    }
} 









//GESTION BURGER MENU
.header__burger{

    cursor: pointer;
    transform: translateX(0px);

    .header__line{
        stroke: var(--main-font-color);
        transition: 0.3s;
        transform: rotate(0deg) translateX(0px) translateY(0px);
        transform-origin: center left;
    }
    .header__line2{
        transition: 0.15s;
    }
}

.header--opened {
    .header__line1{
        transform: translateX(0px) translateY(-4.2px) rotate(45deg);
    }

    .header__line3{
        transform: translateX(0px) translateY(4.2px) rotate(-45deg);
    }

    .header__line2{
        opacity: 0;
    }
}



//responsive DESKTOP
@media (min-width: responsive.$tablet) {

    .header{
        //    background-color: var(--white-color);

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        height: 48px;
    }

.header__links{
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1;
}

    .header__link{
        opacity: 1;
        transform: translateX(0px);
        font-size: 14px;
        margin-left: 24px;

        &.phonelink {

            a{
                padding: 4px 8px 4px 4px;
            }
            svg{
                height: 18px;
                width: 18px;
            }
        }
        a::after{
            height: 1px;
        }
    }

    .header__bottom, .header__top{
        height: auto;
    }

    .header--opened{
        .header__bottom, .header__top{
            height: auto;
        }
    }

    .header__burger{
        display: none;
    }

}


    .header__maxWidth{
        position: fixed;
        width: 100%;
        max-width: responsive.$veryLarge;
        z-index: 1000;
    }

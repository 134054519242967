@use "../base/_responsive.scss" as responsive;

.services{
    display: grid; 
    grid-template-columns: 1fr;  
}

.service{
    padding: 2rem 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.service-imageContainer{

    margin:0 auto;
    height: 75px;
    width: 75px;
    border-radius: 1rem;
    padding: 1rem;
    background-color: var(--main-color);
    img{
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
}

.service-title,
.service-list{
    color: var(--black-font-color);
    font-family: 'inter';
}

.service-title{
    
    margin: 0.5rem auto;

    text-align: center;

    font-family: 'inter';
    font-weight: 500;
    font-size: 1.6rem;
}

.service-list ul,
.service-list ol{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    li{
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6;
        text-align: justify;
    }

} 

.service-link{
    margin-top: 1.2rem;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 1.2rem;
    color: var(--main-color);
}

@media (min-width: responsive.$small){


    .services{
        grid-template-columns: repeat(3, 1fr);  
    }
}

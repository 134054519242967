@use "../base/_responsive.scss" as responsive;

.kibry-blocks{
    width: 100%;
    max-width: 716px;
    margin: 5rem auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2,h3,h4,h5,h6{
        color: var(--main-font-color);
        // --black-font-color
    }

    h2,h3{
        font-weight: 700;
        margin-bottom: 2.5rem;
        margin-top: 2rem;
    }
    h4,h5,h6{
        font-weight: 600;
        margin-bottom: 1.25rem;
        margin-top: 1rem;
    }

    h2{
        font-size: 2.5rem;
    }
    h3{
        font-size: 2.2rem;
    }
    h4{
        font-size: 2rem;
    }
    h5{
        font-size: 1.8rem;
    }
    h6{
        font-size: 1.6rem;
    }

    p,li{
        color: var(--dark-font-color);
        font-size: 1.2rem;
        letter-spacing: 0.025rem;
        font-weight: 450;
        line-height: 1.4;
        margin: 0.4rem 0;
    }

    strong{
        color: var(--black-font-color);
    }

    strong{ 
        font-weight: 625;
        letter-spacing: 0.033rem;
    }

    em{
        font-style: italic;
        font-weight: 475;
        letter-spacing: 0.033rem;
    }

    s{
        text-decoration: line-through;
    }
    u{
        text-decoration: underline;
    }

    ol{
        list-style:none;
        counter-reset: mycounter;
        padding:0;

        li{
            position:relative; 
            margin-left: 2rem;

            &::before{
                content:counter(mycounter); 
                counter-increment:mycounter;
                position:absolute;
                top:0;
                left:-2rem;
                width: 1.6rem;
                height: 1.6rem;
                line-height: 1.6rem;
                border-radius:50%;
                color: var(--white-font-color);
                background: var(--main-color);
                text-align:center;
            }
        }
    }

    ul{
        list-style:none;
        padding:0;

        li{
            position:relative; 
            margin-left: 2rem;

            &::before{
                content:"";
                position:absolute;
                top:50%;
                transform:translateY(-50%);
                left:-1.7rem;
                width: 1rem;
                height: 1rem;
                line-height: 1rem;
                border-radius:50%;
                color: var(--white-font-color);
                background: var(--main-color);
                text-align:center;
            }
        } 
    }

    figure{
        margin: 2rem 0;
    }
    
    img{
        width: 100%;
    }

    .kirby-figure{
        overflow: hidden;
    }

    .kirby-image{
        width: 100%;
    }

    .kirby-figcaption{
        font-size: 1.2rem;
        font-weight: 350;
        color: var(--dark-font-color);
        margin: 0.25rem 0;

        text-align: center;
    }

    a{
        color: var(--main-color);
        text-decoration: underline;
        font-weight: 450;

        &:hover{
            font-weight: 475;
        }

    }
}
@use "../base/_responsive.scss" as responsive;

.home-avis{
    display: grid; 
    grid-template-columns: 1fr;
    grid-gap: 1.25rem;

    padding-top: 2rem;
}

.home-avi{
    //    padding: 2rem 1rem;
}

.home-avi-imageContainer{

    margin:0 auto;
    height: 75px;
    width: 75px;
    border-radius: 1rem;
    padding: 0rem;
    overflow: hidden;
    background-color: var(--main-color);
    img{
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.home-avi-title,
.home-avi-texte{
    color: var(--black-font-color);
    font-family: 'inter';
    text-align: center;
}

.home-avi-title{
    margin: 0.5rem auto;
    
    font-weight: 500;
    font-size: 1.6rem;
}

.home-avi-texte{
    font-size: 1.2rem;
    line-height: 1.3;
    max-width: 400px;
    margin: 0 auto;
}




@media (min-width: responsive.$small){


    .home-avis{
        grid-template-columns: repeat(3, 1fr);  
    }
}

$small: 768px;
$tablet: 1024px;
$veryLarge: 1860px;


@media (min-width: $veryLarge){

    .maxWidth{
        max-width: $veryLarge;
        width: 100%;
        margin: 0 auto;
    }
    

}


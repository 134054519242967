.article-banner{
    width: 100%;
    height: 33.33vh;
    max-height: 400px;

    padding-top: 48px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.banner-caption{
    color: var(--white-font-color);
    font-size: 12.5vmin;
    font-weight: 500;
    letter-spacing: 0.2rem;
}
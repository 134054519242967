@use "../base/_responsive.scss" as responsive;



.proposals__title{
   font-size: 28px;
    font-weight: 600;
    padding-bottom: 12px;
    border-bottom: 2px solid var(--grey-color);
}

.proposal{
    margin-top: 32px;
    margin-bottom: 48px;

}
.proposal__imageContainer{
    overflow: hidden;
    width: 100%;

    height: 35vh;
    border-radius: 24px;
}

.proposal__image{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.proposal__title{
    margin-top: 12px;

}

.proposal__description{
    margin-top: 32px;
}

.proposal__link{
    margin-top: 32px;
    display: inline-block;

}

@media (min-width: responsive.$tablet){

    .proposals__title{
        padding-bottom: 12px;
        font-size: 32px;
    }

    .proposal__image{
        border-radius: 24px;
    }


    .proposal{
        width: 100%;
        display: flex;
        flex-direction: row;

        &:nth-child(odd){
            flex-direction: row-reverse;

            .proposal__text{
                padding: 32px 32px 32px 0px;
            }
            .proposal__imageContainer{
                padding: 32px 0px 32px 32px;
            }
        }
    }

    .proposal__imageContainer, .proposal__text{
        width: 50%;
        height: 50vh;
    }

    .proposal__imageContainer{
        padding: 32px 32px 32px 0px;
    }
    .proposal__text{
        padding: 32px 0px 32px 32px;
    }


    .proposal__imageContainer{
        max-height: 45vh;
    }
    
}

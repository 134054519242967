@use "../base/_responsive.scss" as responsive;


body{
    background-color: var(--grey-color);
}

.main{
    min-height:  calc(100vh - 48px);
//    padding: 48px 16px 0px 16px;
    box-sizing: border-box;
}

/*
.main__content{
    max-width: 800px;
    margin: 0px auto;
    margin-top: 32px;
}
*/

.main-section{
    padding: 2rem 2rem;

    &.main-section--paddin-top{
        padding-top: 6rem;
    }
}



.title-container{
    margin: 1.2rem 0;
    
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: relative;

    &::before{
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        width: 100%;
        height: 3px;
        background-color: var(--main-color);
    }
}


.h2{
    color: var(--black-font-color);
    background-color: var(--grey-color);

    display: inline-block;
    padding: 0 0.5rem;

    position: relative;
    z-index: 1;

    font-family: 'inter';
    font-weight: 340;
    font-size: 2rem;
    text-align: center;

    max-width: 80%;
}



.kirby-writer{

    h2,h3,h4,h5,h6{
        color: var(--main-font-color);
        // --black-font-color
    }
    h2,h3{
        font-weight: 700;
        margin-bottom: 2.5rem;
        margin-top: 2rem;
    }
    h4,h5,h6{
        font-weight: 600;
        margin-bottom: 1.25rem;
        margin-top: 1rem;
    }

    h2{
        font-size: 2.5rem;
    }
    h3{
        font-size: 2.2rem;
    }
    h4{
        font-size: 2rem;
    }
    h5{
        font-size: 1.8rem;
    }
    h6{
        font-size: 1.6rem;
    }


    p{
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: var(--black-font-color);
        font-family: 'inter';
        font-weight: 300;
        margin-bottom: 1rem;
    }

    li{
        color: var(--dark-font-color);
        font-size: 1.2rem;
        letter-spacing: 0.025rem;
        font-weight: 450;
        line-height: 1.4;
        margin: 0.4rem 0;
    }

    strong{
        color: var(--black-font-color);
    }

    strong{ 
        font-weight: 625;
        letter-spacing: 0.033rem;
    }

    em{
        font-style: italic;
        font-weight: 475;
        letter-spacing: 0.033rem;
    }

    s{
        text-decoration: line-through;
    }
    u{
        text-decoration: underline;
    }
}

.kirby-textarea{

    width: 100%;
    max-width: responsive.$small;
    margin: 0 auto;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--black-font-color);
    font-family: 'inter';
    font-weight: 300;
    margin-bottom: 1rem;
}


@media (min-width: responsive.$small){

    .main-section{
        padding: 2rem 4rem;
    }
}
@use "../base/_responsive.scss" as responsive;
// FIX FONT-SIZE (PX TO REM)
html{
    /* 62.5% of 16px browser font size is 10px */
    /* 1.2rem * 10px = 12px */
    font-size: 62.5%;
}

// IMPORT FONTS
@font-face {
    
    font-family: 'inter';
    src: url('../type/Inter.var.woff2') format('woff2-variations'),
         url('../type/Inter-VariableFont_slnt,wght.ttf') format('truetype-variations'); /* Source TTF variable */
    font-weight: 100 900;
    font-style: normal;
}


// INIT FONTS
html, body, *{
    font-family: "inter";
    font-size: 1rem;

}


.regular-text, .small-title, .regular-button{
    font-size: 16px;
    @media (min-width: responsive.$tablet){
        font-size: 18px;  
    }
}

.regular-text{
    line-height: 1.5;
    letter-spacing: 0px;
    font-weight: 400;
    text-align: justify;
}


.small-title{
    font-weight: 600;
    font-size: 24px;
}

.regular-button{
    font-weight: 320;
    padding: 8px 24px;
    font-size: 16px;

    text-transform: uppercase;
    background-color: var(--white-color);
    border: 1px solid var(--grey-color);
    box-shadow: 0px 0px 8px 3px rgba(88,88,88, 0.1);
    border-radius: 24px;

    transition: .3s;
    cursor: pointer;

    &.buttonBlue{
        background-color: var(--main-color);
        color: var(--white-color);
        border: 1px solid var(--main-color);
        &:hover{
            background-color: var(--white-color);
            color: var(--main-color);
        }
    }

    &::after{
        content: " →";
        margin-left: 0px;
        margin-right: 3px;
        transition: .3s;
    }
    &.buttonLeft{
        
        &::after{
            content: "";
            margin-left: 0px;
            margin-right: 0px;
        }
        
        &::before{
            content: "← ";
            margin-left: 3px;
            margin-right: 0px;
            transition: .3s;
        }

        &:hover{
            &::after{
                margin-left: 0px;
                margin-right: 0px;
            }
            &::before{
                margin-left: 0px;
                margin-right: 3px;
            }
        }

    }

    &:hover{
        font-weight: 560;
        background-color: var(--main-color);
        color: var(--white-font-color);

        &::after{
            margin-left: 3px;
            margin-right: 0px;
        }
    }
}


.kirby-text{
    line-height: 1.35;
    font-weight: 400;

    h1{
        font-size: 1.7rem;
        font-weight: 500;
        margin-bottom: 32px;
    }
    h2{
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 24px;
    }
    h3{
        font-size: 1.4rem;
        font-weight: 450;
        margin-bottom: 24px;
    }

    p{
        font-size: 1.2rem;
        margin-bottom: 12px;
    }

    a{
        text-decoration: underline;
        color: var(--main-font-color);
        &:hover{
            color: var(--main-color);
        }
    }
    ul{
        list-style: disc;
        margin-left: 24px;
        margin-bottom: 12px;
        font-size: 1.2rem;
    }



    strong{
        font-weight: 550;
    }

    em{
        font-style: italic;
    }
}
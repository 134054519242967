@use "../base/_responsive.scss" as responsive;

.footer{
    background-color: var(--grey-color);
    padding: 32px 16px;
}

.footer_container{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}

.footer__link, .footer__para{
    padding-bottom: 9px;
    color: var(--black-font-color);
    font-weight: 400;
}

.footer_importantData{
    font-weight: 600;
}

.footer__link{
    display: block;
    text-decoration: underline;

    opacity: 0.8;

    transition: .3s;
    &:hover{
        opacity: 1;
        font-weight: 600;
    }
}

.footer_container_content{
    margin-bottom: 1rem;
}


@media (min-width: responsive.$tablet){

    .footer_container{
        grid-template-columns: 1fr 1fr;
    }

    .footer__link, .footer__para{
        text-align: center;
    }
    
}

:root{

// MAIN BACKGROUND COLORS
--main-color: #1d3b79;
--white-color: #FFF;
--grey-color: #EEECE6;


// FONT SIZE TYPEFACE
--black-font-color: #000;
--dark-font-color: #2A2A2A;
--main-font-color: #1d3b79;
--grey-font-color: #828282;
--white-font-color: #FFF;

// Padding / Margin
--main-margin: 0.8rem;


//responsive
--tablet: 1024px;

}
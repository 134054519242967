@use "../base/_responsive.scss" as responsive;

.articlesContainer{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
}

.nosArticles-article{
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;


}

.nosArticles-ConteneurTexte{
    padding-bottom: 3.25rem;
    max-width: 400px;
}

.nosArticles-titre,
.nosArticles-texte{
    color: var(--white-font-color);
    text-align: center;
}

.nosArticles-titre{    
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.nosArticles-texte{
    font-size: 1.2rem;
    line-height: 1.4;
    margin-bottom: 2rem;
    height: 10.08rem;
    overflow: hidden;
}

.nosArticles-lien{
    display: block;
    color: var(--main-color);
    background-color: var(--white-color);
    padding: .75rem 1.5rem;
    border-radius: 1rem;
    transition: .3s;
    &:hover{
        background-color: var(--main-color);
        color: var(--white-color);
    }
}




@media (min-width: responsive.$small){


    .articlesContainer{
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (min-width: responsive.$tablet){

    .articlesContainer{
        grid-template-columns: repeat(3, 1fr);
    }
}
@use "../base/_responsive.scss" as responsive;

.banner{
    min-height: 100vh;
    width: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    padding: 72px 12px 24px 12px;
    box-sizing: border-box;
}

.banner__soustitre,
.banner__title,
.banner__introduction{
    color: var(--white-font-color);
    text-align: center;
    max-width: 550px;
}

.banner__title{
    font-size:  3rem;
    line-height: 1;
    font-weight: 725;
    color: var(--grey-color);
}

.banner__soustitre{
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
    letter-spacing: 0.2px;
    font-weight: 550;
}

.banner__introduction{
    margin-top: 2rem;
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 550;
}

.banner__list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.banner__list__li{
    margin: 1rem 1rem;
}

.banner__link{
    display: block;
}

.banner__link{
    padding: 0.75rem 1rem;
    background-color: var(--white-color);
    border: 2px solid var(--white-color);
    border-radius: 1rem;
    text-decoration: none;

    color: var(--main-font-color);
    font-size: 1rem;
    text-transform: uppercase;

    &.special{
        background-color: var(--main-color);
        border: 2px solid var(--main-color);
        color: var(--white-color);
    }
}





@keyframes typeWritter {
    0% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.animatedBanner{
    opacity: 0;
   animation: typeWritter 0.05s cubic-bezier(0.55, 0.60, 0.55, 1);
   animation-fill-mode: forwards;
}

@media (min-width: responsive.$tablet){



    .banner__title{
        font-size:  8rem;
        line-height: 1;
    }
    .banner__soustitre{
        font-size: 1.25rem;
    }
    .banner__introduction{
        font-size: 1.5rem;
    }
    .banner__list{
        flex-direction: row;
    }

    .banner__link{
        margin: 0px 24px;
    }


    .banner__figure{
        display: block;
        padding-top: 24px;
        height: 30vh;
        width: 100%;
        position: relative;
    }
    
    .banner__image{
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 24px;
    }
    
    .banner__figcaption{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px;
        font-weight: 500;
        box-sizing: border-box;
        text-shadow: 0px 0px 18px black, 0 0 50px rgb(0, 0, 0), 0 0 4px rgb(0, 0, 0);
        color: var(--white-font-color);
    }
}

@use "../base/_responsive.scss" as responsive;


.pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin: 2.4rem 0;
}

.nav-pagination{
    display: flex;
    flex-direction: row;
}

.pagination-primary,
.pagination-numeric{
    margin: 0 0.25rem;
    font-weight: 600;
    &.pagination-primary--disabled{
        opacity: 0.5;
    }
}

.pagination-numeric{
    
    a{
        
        padding: 0.4rem 0.8rem;
        border-radius: 50%;
        line-height: 1;
        border: 2px solid var(--black-font-color);
        color: var(--black-font-color);
    }

    &:has(.current-pagination){

        a{
            background: var(--main-color);
            color: var(--white-font-color);
            border-color: var(--main-color);
        }
    }
}

@media (min-width: responsive.$tablet){



}
    

